<template>
  <div class="serviceSupCss">
    <Navbar :pageName="pageName" />
    <div class="titleContentCss"><div class="titleCss">{{pageName}}</div></div>
    <div class="serviceSupMsgCss">
      <div v-for="(item, index) in serviceSupData.serviceSupList" :key="index">
        <div class="msgTitleCss">{{ item.title }}</div>
        <div v-for="(info, msgIndex) in item.infos" :key="msgIndex">
          <div class="subheadCss">{{info.subhead}}</div>
          <div class="msgCss">{{ info.msg }}</div>
        </div>
        <div v-for="(imgItem, imgIndex) in item.imgs" :key="imgIndex">
          <img :src="imgItem.url" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/views/home/components/Navbar.vue'

export default {
  name: 'serviceSup',
  components: {
    Navbar
  },
  data() {
    return {
      pageName: '',
      serviceSupData: {
        serviceSupList: [
          {
            title: '一、服务宗旨',
            infos: [
              {
                msg: '帝图建有完善的“以客户为中心的客户服务管理体系”，服务贯穿于产品的整个生命周期，包括售前、售中、售后等各个环节，并在全国重点区域建有专业的物联网技术支撑和运维团队，旨在为客户提供贴身的“保姆式”全方位物联网服务。'
              }
            ]
          },
          {
            title: '二、服务流程',
            imgs: [
              {
                url: require('@/assets/images/weixiuliucheng.png')
              }
            ]
          },
          {
            title: '三、服务内容',
            infos: [
              {
                subhead: '1、技术培训',
                msg: '帝图建有完善的“以客户为中心的客户服务管理体系”，服务贯穿于产品的整个生命周期，包括售前、售中、售后等各个环节，并在全国重点区域建有专业的物联网技术支撑和运维团队，旨在为客户提供贴身的“保姆式”全方位物联网服务。'
              },
              {
                subhead: '2、标准运维服务',
                msg: '产品验收后，为保证系统长期稳定运行，针对监控平台软件，我方提供合同签订后一年的免费标准运维服务，提供的标准运维服务内容有：软件BUG修复、定期健康监视、磁盘维护和日志维护等4项内容。'
              },
              {
                subhead: '3、工程师现场服务',
                msg: '远程方式解决不了的问题，我公司会根据问题类型安排专业工程师到达现场，配合用户解决系统问题。'
              },
              {
                subhead: '4、需求更新服务',
                msg: '帝图提供的需求更新服务主要包括功能扩展服务、性能扩展服务和数据迁移服务三部分。'
              }
            ]
          }
        ]
      }
    }
  },
  mounted() {
    this.pageName = this.$route.query.pageName
  }
}
</script>

<style lang="stylus" scoped>
titleWidth = 59.8rem

.serviceSupCss {
  padding-bottom 4rem
  width 99.8rem
  background-color #333439
  font-size 0.8rem
  color white
  .titleContentCss {
    padding 2rem 20rem 0 20rem
    .titleCss {
      width titleWidth
      font-size 2rem
      height 4rem
      line-height 4rem
      border-bottom 1px solid white
    }
  }
  .serviceSupMsgCss {
    padding 0 20rem
    .msgTitleCss {
      margin-top 1rem
      height 1.5rem
      line-height 1.5rem
    }
    .subheadCss {
      margin-top 0.5rem
    }
    .msgCss {
      text-indent 1.6rem
    }
    img {
      max-width titleWidth
    }
  }
}
</style>
